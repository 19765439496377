@charset "UTF-8";
@import "@angular/material/prebuilt-themes/deeppurple-amber.css";
/* You can add global styles to this file, and also import other style files */
.header {
  width: 100vw;
  background-image: url("assets/images/header.jpg");
  background-position: bottom;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 10px;
}

.header .container {
  width: 80vw;
}

.header .container .logo {
  align-items: left;
  justify-content: left;
}

.header .container .abn {
  padding-left: 10px;
  font-size: 14px;
  float: left;
}

.header .container .lang-bar {
  font-size: 14px;
  float: right;
  margin-right: 10vw;
}

.lang-bar .lang {
  text-decoration: none;
}

* {
  font-family: "Trebuchet MS";
  color: #000000;
}

img {
  border-width: 0px;
}

body {
  margin-left: 0px;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  color: #ffffff;
}

.bg3 {
  background: url(assets/images/header1.png);
  background-repeat: no-repeat;
}

a.read:link {
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  text-decoration: underline;
  border-bottom: 1px dotted;
  text-shadow: 2px 2px 2px #c0c0c0;
}

a.redirect:visited {
  text-decoration: none;
}

a.redirect:hover {
  text-decoration: underline;
  color: #cc0000;
}

a.redirect:active {
  text-decoration: underline;
}

.pad1 {
  padding: 0px 10px 0px 10px;
}

.round {
  -moz-border-radius: 15px;
  border-radius: 15px;
}

.bodybg {
  background: url(assets/images/004-subtle-light-pattern-background-texture.jpg);
}

/* Typography */
h1 {
  font-weight: 400;
  font-size: 30px;
  text-transform: none;
  letter-spacing: 0em;
  word-spacing: 0em;
  line-height: 1em;
  color: #31b0d8;
  text-shadow: 1px 1px 1px #ffffff;
}

.title {
  font-style: normal;
  font-weight: 1200;
  font-size: 30px;
  text-transform: none;
  text-decoration: none;
  letter-spacing: 0em;
  word-spacing: 0em;
  line-height: 0.5;
  color: #6a6a6a;
  text-shadow: 2px 2px 2px #c0c0c0;
}

h2 {
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 0em;
  word-spacing: 0em;
  line-height: 1em;
  color: #31b0d8;
  text-shadow: 1px 1px 1px #ffffff;
}

h3 {
  color: #299cce;
  font-size: 16px;
  font-style: bold;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 0em;
  text-shadow: 1px 1px 1px #ffffff;
  word-spacing: 0em;
}

span, p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-transform: none;
  text-decoration: none;
  letter-spacing: 0em;
  word-spacing: 0em;
  line-height: 1.4;
  color: #333333;
  text-shadow: 2px 2px 2px #c0c0c0;
}

.boldred {
  font-weight: bold;
  color: #cc0000;
}

ul.footer {
  list-style-image: url(assets/images/5.gif);
  padding: 0px;
  margin: 0px;
  list-style-position: inside;
  font-family: "PT Sans", sans-serif;
  font-style: normal;
  font-weight: Normal;
  font-size: 14px;
  text-transform: none;
  text-decoration: none;
  letter-spacing: 0em;
  word-spacing: 0em;
  color: #6a6a6a;
}

blockquote::before {
  color: #7a7a7a;
  content: "“";
  display: block;
  font-size: 80px;
  left: 5px;
  position: absolute;
  top: -25px;
}

blockquote {
  color: #383838;
  font-family: Georgia, serif;
  font-size: 14px;
  font-style: italic;
  left: 0;
  line-height: 1.45;
  margin: 0.25em 0;
  margin-top: 0.25em;
  margin-top: 25px;
  padding: 0.25em 40px;
  position: relative;
  width: 71.11vw;
}

blockquote cite::before {
  content: "— ";
}

blockquote cite {
  color: #999999;
  display: block;
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
  text-align: left;
}

.split-line {
  height: 15px;
  width: 80vw;
  background: url(assets/images/split-line-1-.png) no-repeat scroll center;
}